import React from 'react';
import TranslatedImage from '../TranslatedImage';
import { render } from '../../../newRender';
describe('TranslatedImage', () => {
  it('should return a translated image', () => {
    const props = {
      style: {
        width: 429,
        height: 222,
      },
    };
    const { container } = render(<TranslatedImage {...props} />);
    const translatedImage = container.querySelector('.translated-image');
    expect(translatedImage).toBeDefined();
  });
});
