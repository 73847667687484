import React from 'react';
import BarChart from '../BarChart';
import { render } from '../../../newRender';
describe('BarChart', () => {
  it('should return a bar chart', () => {
    const { container } = render(<BarChart />);
    const barChart = container.querySelector('.bar-chart');
    expect(barChart).toBeDefined();
  });
});
